$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

#responsive-dialog-title {
  padding: 0px 16px 12px 16px;
  margin: 0px 10px 10px 10px;
}

.closeComplaintCont {
  .splitGrid {
    margin-top: 30px;
  }
}

.closeMatTable {
  width: 100% !important;
}

.matTableClass {
  .MuiTableCell-root {
    padding: 7px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
  .MuiTableRow-root {
    font-size: 13px;
  }
  width: 100%;
}

.tableTitle {
  margin: 20px 0px 20px 0px;
}

.closingRemarksClass {
  margin: 24px !important;
}
.closingRemarks {
  margin-bottom: 24px !important;
}

.bod {
  margin-left: -50px;
}
.marginBtm {
  margin-bottom: 20px;
}
.marginLft {
  margin-bottom: 10px;
}
.paddingBtm {
  padding-bottom: 20px;
}
.borderBtm {
  border-bottom: 3px dashed var(--btn-primary-bg-active);
}
.accordionCont {
  margin-top: 20px;

  .MuiAccordionSummary-root {
    background-color: var(--input-bg-color);
  }
  .MuiAccordionSummary-content {
    p {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.MuiStepIcon-active {
  border: 3px dotted #00646e;
}
.stepperCont {
  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: bold;
  }
}
.mt-05 {
  margin-top: 0.5em !important;
}
.acceptCont {
  padding: 0px !important;
  margin-top: -14px !important;
}
.customerField {
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    margin-bottom: 8px;
    margin-left: 10px;
  }
  .MuiTextField-root {
    .MuiInputLabel-animated {
      font-size: inherit;
      font-family: inherit;
      margin-left: 10px;
    }
  }
}
.alignRight {
  text-align: right;
}

@media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
  .alignRight {
    text-align: center;
  }
  .width75 {
    width: 40%;
  }
}
.width75 {
  width: 75%;
}
.textCenter {
  text-align: center;
  margin: 35px;
}
