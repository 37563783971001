.reportsCont {
  .checkBoxCont {
    .MuiFormControlLabel-labelPlacementStart {
      margin-left: 0px;
    }
    margin: 15px 0px 15px 0px;
  }
}
.align-center {
  align-items: center;
}

.customerField {
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    margin-bottom: 8px;
    margin-left: 10px;
  }
  .MuiTextField-root {
    .MuiInputLabel-animated {
      font-size: inherit;
      font-family: inherit;
      margin-left: 10px;
    }
  }
}

.css-1r4vtzz,
.css-48ayfv {
  width: 100% !important;
  height: 3.9em !important;
  background: #e8f8f8 !important;
  border-bottom: 1px solid #00b3b3 !important;
  box-shadow: 0 0 0 0 !important;
}
.css-11unzgr {
  max-height: 200px !important;
}
.css-ik6y5r {
  box-shadow: 0 0 0 0 !important;
  background: #e8f8f8 !important;
}
.css-48ayfv + div {
  background: #e8f8f8 !important;
}

.division_field div {
  width: 100%;
  z-index: 101;
}
.css-1jllj6i-control,
.css-1jllj6i-control:hover {
  width: 90% !important;
}
.css-1hwfws3,
.css-1hwfws3:hover {
  width: 90% !important;
}
.css-1wy0on6,
.css-1hwfws3:hover {
  width: 10% !important;
}
.css-1v99tuv,
.css-1hwfws3:hover {
  color: var(--select-text-color);
  font-family: "SiemensSans", sans-serif;
}
