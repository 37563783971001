.add-button {
  display: flex;
  justify-content: space-between;
}
.MuiSelect-select.Mui-disabled {
  cursor: no-drop;
}
.clr-white {
  li {
    color: var(--white);
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.policy-header {
  //text-decoration: underline;
  cursor: default;
  color: var(--blue-green);
}
.policy-header:hover {
  color: var(--blue-green);
}
.policy-icon {
  margin: -15px;
  font-size: 2.25rem;
}
.policy-icon:hover {
  color: var(--blue-green);
}
.MuiSvgIcon-root.MuiSelect-icon {
  right: -10px !important;
}
// .table-padding{
//   padding: 40px;
// }
