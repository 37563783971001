.online-pact-banner {
  background: url("../../../assets/images/OnlinePact.svg");
  background-size: cover;
  background-position: center;
  height: 50vh;
}

.online-pact-pagetitle-block {
  padding: 0 40px;
}

.online-pact-nav-menu {
  top: 50px !important;
}

.onlinepactnavbar-header {
  padding: 10px 20px;
  /* background: var(--white); */
  background: var(--btn-hero-bg-active);
}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.op-container-block {
  padding: 20px;
  box-shadow: 0 0px 10px var(--btn-hero-bg-active);
  /* background: var(--white); */
}

.op-list-item {
  padding: 5px !important;
  /* background: var(--btn-hero-bg-active) !important; */
}

.op-list-item a {
  width: 100%;
  padding: 10px 15px;
}

.back-to-sales-btn {
  margin-top: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .onlinepactnavbar-header {
    flex-wrap: wrap;
  }
}
ul,
li {
  background: white !important;
}
ul,
li:hover {
  background: var(--btn-hero-bg-active) !important;
}
.align-end {
  align-items: flex-end;
}
