#responsive-dialog-title {
  padding: 0px 16px 12px 16px;
  margin: 0px 10px 10px 10px;
}

.buttonContainer {
  padding-top: 30px;
}

.dashed {
  border-width: 2px;
}

hr.dashed {
  border-top: 2px dashed #008aa6;
  margin: 1.5em 0em 1.5em 0em;
}

.file-upload-form {
  .file-upload {
    margin-right: 24px;

    label {
      transform: translate(12px, 10px) scale(0.75);
    }
  }
}

.gridMargin {
  padding-top: 10px;
}
.resultMatTable {
  margin-top: 25px;
}

.MuiTextField-root {
  background-color: var(--input-bg-color);
}

#date-picker-dialog-label {
  transform: translate(12px, 10px) scale(0.75);
}
#date-picker-dialog {
  padding: 9px 12px 10px;
}

.mandatoryText {
  color: var(--red);
  font-family: "SiemensSans-Bold";
}
.fileSize {
  margin-left: 10px;
}
#multiEmailCont {
  .react-multi-email,
  .react-multi-email.empty > span[data-placeholder],
  .react-multi-email > input {
    background: var(--input-bg-color) !important;
  }
  .react-multi-email {
    min-height: 83px;
    max-height: 83px;
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #00b3b3;
  }
  .react-multi-email.empty > span[data-placeholder] {
    color: black;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    padding: 12px 10px 10px 7px;
  }
}
.mt-05 {
  margin-top: 0.5em !important;
}
#multiEmailCont {
  input {
    color: red;
  }
}
.multiEmailText {
  padding: 19px 10px 0px 12px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  background-color: var(--input-bg-color);
}

.fileDetails {
    color: #099;
    font-style: italic;
    text-decoration: underline;
    align-self: center !important;
}

.fileGrid {
    align-self: center !important; 
    display: flex !important;
}
.deleteIcon{
    color: #ff5454 !important;
}